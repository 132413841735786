import mapVerify from '@/utils/formValidate'
import myTransform from '@/libs_sz/utils/transform'

const getFormatter = (data, value) => {
  const getValue =
    myTransform.arrToObject(data, 'value', 'label')[value] || value
  return getValue === 'null' || getValue === 0 ? '' : getValue
}

export const getMoreQueryFormData = (that, row) => {
  const moreQueryData = [
    // 策略编码
    {
      name: 'strategyCode',
      span: 12,
      component: 'elInput',
      label: that.$t('lang.gles.strategy.strategyCode'),
      clearable: true
    },
    // 策略名称
    {
      name: 'strategyName',
      span: 12,
      component: 'elInput',
      label: that.$t('lang.gles.strategy.strategyName'),
      clearable: true
    },
    // 位置类型
    {
      name: 'locationType',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.locationType'),
      clearable: true,
      data: that.locationTypeList || []
    },
    // status
    {
      name: 'status',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.base.status'),
      clearable: true,
      data: that.statusFlagList || []
    }
  ]
  return moreQueryData
}
// 查询列表列表
export const getSearchTableItem = (that) => {
  const searchTableItem = [
    // 策略编码
    {
      prop: 'strategyCode',
      sortable: true,
      label: that.$t('lang.gles.strategy.strategyCode'),
      isShow: true
    },
    // 策略名称
    {
      prop: 'strategyName',
      label: that.$t('lang.gles.strategy.strategyName'),
      isShow: true
    },
    // 位置类型
    {
      prop: 'locationType',
      label: that.$t('lang.gles.strategy.locationType'),
      formatter(row, column) {
        return getFormatter(that.locationTypeList, row[column])
      },
      isShow: true
    },
    // 状态
    {
      prop: 'status',
      label: that.$t('lang.gles.common.statusFlag'),
      slotName: 'enableStatus',
      isShow: true
    },
    // 备注
    {
      prop: 'remark',
      label: that.$t('lang.gles.strategy.remark'),
      isShow: true
    }
  ]
  searchTableItem.forEach((item) => (item.showOverflowTooltip = true))
  return searchTableItem
}

// 编辑弹框 基础信息
export const getEditBaseFormData = (that, options, row) => {
  const editBaseInfoFormData = [
    // 策略编码
    {
      name: 'strategyCode',
      value: row.strategyCode || '',
      span: 8,
      component: 'elInput',
      label: that.$t('lang.gles.strategy.strategyCode'),
      rules: mapVerify(['required', 'inputCode']),
      clearable: true,
      appendSlotName: 'inputCodeTipIcon',
      class: 'tip-icon'
    },
    // 策略名称
    {
      name: 'strategyName',
      value: row.strategyName || '',
      span: 8,
      component: 'elInput',
      label: that.$t('lang.gles.strategy.strategyName'),
      placeholder: that.$t('lang.gles.common.inputLen200'),
      rules: mapVerify(['required', 'inputLen200']),
      clearable: true
    },
    // 备注
    {
      name: 'remark',
      value: row.remark || '',
      span: 6,
      component: 'elInput',
      label: that.$t('lang.gles.strategy.remark'),
      clearable: true
    },
    // 位置类型
    {
      name: 'locationType',
      value: row.locationType || '',
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.locationType'),
      rules: mapVerify(['required']),
      clearable: true,
      data: options.locationTypeList,
      mchange: that.onPositionTypeChange
    }
  ]
  return editBaseInfoFormData
}
// 来源条件
export const getSouceConditionFormItem = (that, type, row) => {
  const sourceConditionFormItem = [
    // 条件项
    {
      isShow: true,
      prop: 'selectedCondition',
      align: 'center',
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.conditionItem'),
      rules: mapVerify(['required']),
      clearable: true,
      data:
        that.sourceConditionList.filter((item) => {
          if (type === 1 || row.locationType === 1)
            return !item.label.includes('固定货位')
          else return !item.label.includes('库位')
        }) || []
    },
    // 运算符
    {
      isShow: true,
      prop: 'operators',
      component: 'elSelect',
      align: 'center',
      label: that.$t('lang.gles.strategy.operator'),
      rules: mapVerify(['required']),
      clearable: true,
      data: that.operatorList || []
    },
    // 条件值
    {
      isShow: true,
      prop: 'conditionValue',
      component: 'elInput',
      align: 'center',
      label: that.$t('lang.gles.strategy.conditionValue'),
      rules: mapVerify([
        'required',
        'positiveNonnegativeInteger',
        'inputNumberLen6'
      ]),
      placeholder: that.$t('lang.gles.strategy.pleaseInputNumber'),
      clearable: true
    }
  ]
  return sourceConditionFormItem
}

// 来源范围table
export const getSourceTableData = (that) => {
  const targetTableData = [
    {
      prop: 'factoryId',
      label: that.$t('lang.gles.strategy.factory'),
      formatter(row, column) {
        return getFormatter(that.factoryList, row[column])
      }
    },
    {
      prop: 'workshopId',
      label: that.$t('lang.gles.strategy.workshop'),
      formatter(row, column) {
        return getFormatter(that.workshopList, row[column])
      }
    },
    {
      prop: 'warehouseId',
      label: that.$t('lang.gles.strategy.warehouse'),
      formatter(row, column) {
        return getFormatter(that.warehouseList, row[column])
      }
    },
    {
      prop: 'warehouseAreaId',
      label: that.$t('lang.gles.strategy.warehouseArea'),
      formatter(row, column) {
        return getFormatter(that.warehouseAreaList, row[column])
      }
    }
  ]
  if (that.formModel.locationType === 2) {
    targetTableData.push({
      prop: 'fixedGoodsAllocationId',
      label: that.$t('lang.gles.strategy.fixGoodsPosition'),
      formatter(row, column) {
        return getFormatter(that.fixGoodsPositionList, row[column])
      }
    })
  } else {
    targetTableData.push({
      prop: 'factoryPositionId',
      label: that.$t('lang.gles.base.belongingFactoryPosition'),
      formatter(row, column) {
        return getFormatter(that.factoryPositionList, row[column])
      }
    })
  }
  targetTableData.forEach((item) => (item.showOverflowTooltip = true))
  return targetTableData
}
export const getAddSourceRangeFormData = (that, formModel, options) => {
  const editTargetRangeFormData = [
    // 工厂
    {
      name: 'factoryId',
      value: '',
      span: 20,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.factory'),
      rules: mapVerify(['required']),
      clearable: true,
      data: options.factoryList || [],
      mchange: that.handleFactoryChange
    },
    // 车间
    {
      name: 'workshopId',
      value: '',
      span: 20,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.workshop'),
      rules: that.$refs.myRangeForm?.formModel.warehouseId
        ? []
        : mapVerify(['required']),
      clearable: true,
      data: that.workshopList || [],
      disabled: !!that.$refs.myRangeForm?.formModel.warehouseId,
      mchange: that.handleWorkshopChange
    },
    // 仓库
    {
      name: 'warehouseId',
      value: '',
      span: 20,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.warehouse'),
      rules: that.$refs.myRangeForm?.formModel.workshopId
        ? []
        : mapVerify(['required']),
      clearable: true,
      data: that.warehouseList || [],
      disabled: !!that.$refs.myRangeForm?.formModel.workshopId,
      mchange: that.handleWarehouseChange
    },
    // 库区
    {
      name: 'warehouseAreaId',
      value: '',
      span: 20,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.warehouseArea'),
      rules: mapVerify(['required']),
      clearable: true,
      data: that.warehouseAreaList || [],
      mchange: that.handleWarehouseAreaChange
    }
  ]
  if (formModel.locationType === 2) {
    editTargetRangeFormData.push({
      name: 'fixedGoodsAllocationId',
      value: [],
      span: 20,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.fixGoodsPosition'),
      clearable: true,
      multiple: true,
      data: that.fixGoodsPositionList || [],
      mchange: that.handleFormChange
    })
  } else {
    editTargetRangeFormData.push({
      name: 'factoryPositionId',
      value: [],
      span: 20,
      component: 'RemoteSelect',
      labelText: 'factoryPositionCode',
      showLabel: 'factoryPositionCode',
      label: that.$t('lang.gles.base.factoryPosition'),
      clearable: true,
      multiple: true,
      // data: that.factoryPositionList || [],
      mchange: that.handleFormChange
    })
  }
  return editTargetRangeFormData
}

// 目标范围
export const getTargetRangeFormData = (that, row = {}) => {
  const formData = [
    // 库区
    {
      name: 'warehouseAreaId',
      value: row.warehouseAreaId || '',
      span: 6,
      component: 'elSelect',
      label: that.$t('lang.gles.strategy.warehouseArea'),
      // rules: mapVerify(['required']),
      clearable: true,
      data: that.warehouseAreaList || [],
      mchange: that.onWarehouseAreaChange
    }
  ]
  if (that.formModel.locationType === 2) {
    formData.push({
      name: 'fixedGoodsAllocationId',
      value: row.fixedGoodsAllocationId || '',
      span: 6,
      component: 'AvaSelect',
      label: that.$t('lang.gles.strategy.fixGoodsPosition'),
      clearable: true,
      options: that.fixGoodsPositionFormList || [],
      mchange: that.onFormChange
    })
  } else {
    formData.push({
      name: 'factoryPositionId',
      value: row.factoryPositionId || '',
      span: 6,
      component: 'AvaSelect',
      // labelText: 'factoryPositionCode',
      label: that.$t('lang.gles.base.factoryPosition'),
      clearable: true,
      options: that.factoryPositionFormList || [],
      mchange: that.onFormChange
    })
  }
  return formData
}
