<template>
  <div class="basic-data-add">
    <el-card class="add-card">
      <div class="form_title b mb15 mt15">
        {{ title }}{{ $t("lang.gles.strategy.tallyStrategy") }}
      </div>
      <div class="basicInfo">
        <el-divider content-position="left">
          {{ $t("lang.gles.common.basicInfo") }}
        </el-divider>
        <m-form
          ref="myForm"
          :form-data="basicFormData"
          label-position="right"
          :label-width="120"
          :extend-config="extendFormConfig"
        >
          <template #inputCodeTipIcon>
            <el-tooltip
              popper-class="workshop_code_tip-icon"
              effect="dark"
              :content="$t(autoGeneCodeTip)"
              placement="right"
            >
              <i class="el-icon-tip el-icon-question tip" />
            </el-tooltip>
          </template>
        </m-form>
      </div>
      <el-button
        type="primary"
        class="ml20"
        @click="onCancel"
      >
        {{ $t("lang.gles.common.back") }}
      </el-button>
    </el-card>
    <el-card v-if="rowDetail.locationType || formModel.locationType" class="add-card mt20">
      <div class="form_title b mb15 mt15">
        {{ $t("lang.gles.strategy.sourceSettings") }}
      </div>
      <!-- 来源条件 -->
      <div class="condition">
        <el-divider content-position="left">
          <span class="required-icon">*</span>
          {{ $t("lang.gles.strategy.sourceCondition") }}
        </el-divider>
        <p class="ml30 mb10">
          {{ $t("lang.gles.strategy.meetTheFollowing") }}
          <span class="danger">{{ $t("lang.gles.strategy.All") }}</span>
          {{ $t("lang.gles.strategy.condition") }}:
        </p>
        <div class="ml30">
          <table-form
            ref="sourceConditionTableForm"
            :table-item="sourceConditionTableFormItem"
            :table-data.sync="sourceConditionTableFormData"
            :extend-config="tableExtendConfig"
            :width="'80%'"
            @remove="removeCondition"
          />
          <el-button
            type="text"
            icon="el-icon-circle-plus"
            @click="addSourceCondition"
          >
            {{ $t("lang.gles.strategy.addCondition") }}
          </el-button>
        </div>
        <el-divider content-position="left">
          <span class="required-icon">*</span>
          {{ $t("lang.gles.strategy.sourceRange") }}
        </el-divider>
        <div class="btns ml30">
          <el-button
            type="text"
            icon="el-icon-circle-plus"
            @click="addSourceRange"
          >
            {{ $t("lang.gles.strategy.add") }}
          </el-button>
          <el-button
            type="text"
            style="color: #f56c6c"
            icon="el-icon-delete-solid"
            @click="batchDelRange"
          >
            {{ $t("lang.gles.common.batchDelete") }}
          </el-button>
          <m-table
            ref="sourceRangeEditTable"
            border
            :table-data="sourceRangeTableData"
            :extend-config="extendTableConfig"
            :table-item="sourceRangeTableItem"
            :page-data="null"
            @delRangeRow="delRangeRow"
            @upRow="upRow"
            @downRow="downRow"
            @selection-change="(val) => (selectSourceRange = val)"
          />
        </div>
      </div>
      <div class="form_title b mb15 mt15">
        {{ $t("lang.gles.strategy.targetSetting") }}
      </div>
      <!-- 目标范围 -->
      <div class="appStrategy">
        <el-divider content-position="left">
          {{ $t("lang.gles.strategy.targetRange") }}
        </el-divider>
        <m-form
          ref="targetRangeForm"
          :form-data="targetRangeFormData"
          label-position="right"
          :label-width="120"
          :extend-config="extendFormConfig"
        />
      </div>
      <div class="footer-btns">
        <el-button @click="onCancel">
          {{ $t("lang.gles.common.cancel") }}
        </el-button>
        <el-button v-debounce="onSubmit" type="primary">
          {{ $t("lang.gles.common.confirm") }}
        </el-button>
      </div>
    </el-card>
    <!-- 新增来源范围弹窗 -->
    <add-source-range ref="addSourceRange" v-bind="$attrs" :table-data="sourceRangeTableData" @addRange="updateSourceRangeList" />
  </div>
</template>
<script>
import MForm from '@/libs_sz/components/MForm/MForm'
import TableForm from '@/components/tableForm/index.vue'
import addMixins from '@/mixins/addMixins'
import AddSourceRange from './addSourceRange.vue'
import { mapState, mapActions } from 'vuex'
import { getEditBaseFormData, getSouceConditionFormItem, getSourceTableData, getTargetRangeFormData } from '../data'
export default {
  name: 'AddStrategy',
  components: {
    MForm,
    TableForm,
    AddSourceRange
  },
  mixins: [addMixins],
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => { }
    },
    options: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      title: this.mode === 'add' ? this.$t('lang.gles.common.addNew') : this.mode === 'edit' ? this.$t('lang.gles.common.edit') : this.$t('lang.gles.common.copy'),
      sourceRangeTableData: [],
      sourceConditionTableFormData: [
        { selectedCondition: '', operators: '', conditionValue: '' }
      ],
      tableExtendConfig: {
        operateWidth: 160,
        operate: [
          {
            event: 'remove',
            label: this.$t('lang.gles.common.delete'),
            confirm: true,
            confirmMessage: this.$t('lang.gles.common.deleteConfirmMsg0'),
            customClass: 'danger'
          }
        ]
      },
      extendTableConfig: {
        sortNum: true,
        checkBox: true,
        executeSort: true,
        operate: [
          {
            event: 'upRow',
            label: this.$t('lang.gles.strategy.moveUp')
          },
          {
            event: 'downRow',
            label: this.$t('lang.gles.strategy.moveDown')
          },
          {
            event: 'delRangeRow',
            label: this.$t('lang.gles.common.delete'),
            confirm: true,
            confirmMessage: this.$t('lang.gles.common.deleteConfirmMsg0'),
            customClass: 'danger'
          }
        ]
      },
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: false
      },
      selectSourceRange: [],
      factoryPositionList: [],
      factoryPositionFormList: [],
      fixGoodsPositionList: [],
      fixGoodsPositionFormList: []
    }
  },
  computed: {
    ...mapState('base', ['factoryList', 'workshopList', 'warehouseList', 'warehouseAreaList']),
    ...mapState('strategyManage', ['sourceConditionList', 'operatorList']),
    basicFormData() {
      const row = this.rowDetail || {}
      return getEditBaseFormData(this, { ...this.options }, { ...row })
    },
    sourceConditionTableFormItem() {
      const row = this.rowDetail || {}
      return getSouceConditionFormItem(this, this.formModel.locationType, { ...row })
    },
    sourceRangeTableItem() {
      return getSourceTableData(this)
    },
    targetRangeFormData() {
      const formModel = this.$refs?.targetRangeForm?.formModel || {}
      return getTargetRangeFormData(this, { ...formModel })
    }
  },
  watch: {
    formModel: {
      handler(formModel) {
        this.sourceRangeTableData = []
        this.sourceConditionTableFormData = [{ selectedCondition: '', operators: '', conditionValue: '' }]
      },
      deep: true
    }
  },
  created() {
    this.initSelectList()
    if (this.mode === 'edit' || this.mode === 'copy') {
      const row = this.rowDetail || {}
      this.getTallyStrategyDetail(row.id)
    }
  },
  methods: {
    ...mapActions('strategyManage', ['getSourceConditionList', 'getOperatorList']),
    initSelectList() {
      this.getSourceConditionList()
      this.getOperatorList()
      this.$store.dispatch('base/getFactoryList')
      this.$store.dispatch('base/getWorkshopList')
      this.$store.dispatch('base/getWarehouseList')
      this.$store.dispatch('base/getWarehouseAreaList')
      this.getFactoryPositionList()
      this.getFixGoodsPositionList()
    },
    onPositionTypeChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      if (val) {
        this.$nextTick(() => {
          this.$refs.targetRangeForm.formModel.warehouseAreaId = ''
          // val === 2 ? this.getFixGoodsPositionList() : this.getFactoryPositionList()
        })
      }
    },
    onWarehouseAreaChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.$refs.targetRangeForm.formModel, formItem.name, val)
      this.$refs.targetRangeForm.formModel.fixedGoodsAllocationId = ''
      this.$refs.targetRangeForm.formModel.factoryPositionId = ''
      const params = { warehouseAreaId: this.$refs.targetRangeForm.formModel.warehouseAreaId }
      this.fixGoodsPositionFormList = []
      this.factoryPositionFormList = []
      if (val) {
        if (this.formModel.locationType === 2) {
          this.getFixGoodsPositionList(params)
        } else {
          this.getFactoryPositionList(params)
        }
      }
    },
    onFormChange(val, formItem) {
      this.$set(this.$refs.targetRangeForm.formModel, formItem.name, val)
    },
    /**
     * 获取库位列表
     */
    async getFactoryPositionList(params) {
      const { data, code } = await this.$httpService(this.$apiStore.base('getFactoryPositionDictList'), params)
      if (code) return
      if (params) this.factoryPositionFormList = data.map((item) => { return { label: item.factoryPositionName, value: item.id } })
      if (!params) this.factoryPositionList = data.map((item) => { return { label: item.factoryPositionName, value: item.id } })
    },
    /**
     * 获取固定货位列表
     */
    async getFixGoodsPositionList(params) {
      const { data, code } = await this.$httpService(this.$apiStore.base('getFixGoodsPositionByWarehouseAreaId'), params)
      if (code) return
      if (params) this.fixGoodsPositionFormList = data.map((item) => { return { label: item.code, value: item.id } })
      if (!params) this.fixGoodsPositionList = data.map((item) => { return { label: item.code, value: item.id } })
    },
    // 添加条件
    addSourceCondition() {
      // 添加条件前过滤掉已存在的条件项
      // console.log(this.sourceConditionTableFormData, 'data')
      this.sourceConditionTableFormData.push(
        { selectedCondition: '', operators: '', conditionValue: '' }
      )
    },
    removeCondition({ $index, row }) {
      // console.log(row,"row")
      this.$refs.sourceConditionTableForm.delRowItemDataByIndex($index)
    },
    // 添加来源范围
    addSourceRange() {
      const options = { factoryList: this.factoryList }
      this.$refs.addSourceRange.init({ ...this.formModel }, options)
    },
    batchDelRange() {
      const selectData = this.selectSourceRange
      if (selectData.length < 1) {
        this.$message({
          type: 'error',
          message: this.$t('lang.gles.strategy.atLeastOneRecordCouldBeDelete')
        })
        return
      }
      selectData.forEach((item) => {
        delete item.isEdit
        delete item.isNew
        const findIndex = this.sourceRangeTableData.findIndex((c) => {
          return JSON.stringify(c) === JSON.stringify(item)
        })
        // 清楚当前的tableData
        this.sourceRangeTableData.splice(findIndex, 1)
      })
    },
    // 删除
    delRangeRow(row) {
      this.sourceRangeTableData.splice(row.$index, 1)
    },
    // // 上移，下移
    upRow({ $index, row }) {
      if ($index > 0) {
        const upDate = this.sourceRangeTableData[$index - 1]
        this.sourceRangeTableData.splice($index - 1, 1)
        this.sourceRangeTableData.splice($index, 0, upDate)
      } else {
        this.$message({
          type: 'warning',
          message: this.$t('lang.gles.strategy.firstRecordNotBeMoveUp')
        })
      }
    },
    downRow({ $index, row }) {
      if ($index + 1 === this.sourceRangeTableData.length) {
        this.$message({
          type: 'warning',
          message: this.$t('lang.gles.strategy.lastRecordNotBeMoveUp')
        })
      } else {
        const downDate = this.sourceRangeTableData[$index + 1]
        this.sourceRangeTableData.splice($index + 1, 1)
        this.sourceRangeTableData.splice($index, 0, downDate)
      }
    },
    updateSourceRangeList(val, index) {
      // 判断是否存在重复记录
      if (typeof index === 'undefined') {
        val.forEach((item) => {
          this.sourceRangeTableData.push(item)
        })
      } else {
        this.sourceRangeTableData.splice(index, 1, ...val)
      }
    },
    // edit或copy 下获取详情
    async getTallyStrategyDetail(id) {
      const { code, data } = await this.$httpService(this.$apiStore.strategyManage('detailTallyStrategy'), { id })
      if (code) return
      // 回显
      this.sourceRangeTableData = data.sourceScopeVoList
      this.sourceConditionTableFormData = data.sourceConditionVoList
      // 处理目标范围表单回显
      const targetScopeObj = {
        factoryPositionId: null,
        fixedGoodsAllocationId: null
      }
      data.targetScopeVoList.forEach(item => {
        targetScopeObj.warehouseAreaId = item.warehouseAreaId
        if (item.factoryPositionId) {
          targetScopeObj.factoryPositionId = item.factoryPositionId
          delete targetScopeObj.fixedGoodsAllocationId
        }
        if (item.fixedGoodsAllocationId) {
          targetScopeObj.fixedGoodsAllocationId = item.fixedGoodsAllocationId
          delete targetScopeObj.factoryPositionId
        }
      })
      this.$set(this.$refs.targetRangeForm.formModel, 'warehouseAreaId', targetScopeObj.warehouseAreaId)
      if (targetScopeObj.factoryPositionId) this.$set(this.$refs.targetRangeForm.formModel, 'factoryPositionId', Number(targetScopeObj.factoryPositionId))
      if (targetScopeObj.fixedGoodsAllocationId) this.$set(this.$refs.targetRangeForm.formModel, 'fixedGoodsAllocationId', Number(targetScopeObj.fixedGoodsAllocationId))
      // 更新目标范围下的库位和固定货位
      if (data.locationType === 2) {
        this.getFixGoodsPositionList(targetScopeObj.warehouseAreaId ? { warehouseAreaId: targetScopeObj.warehouseAreaId } : {})
      } else {
        this.getFactoryPositionList(targetScopeObj.warehouseAreaId ? { warehouseAreaId: targetScopeObj.warehouseAreaId } : {})
      }
    },
    onSubmit() {
      this.$refs.myForm.getValidateFormModel().then(async(model) => {
        this.$refs.sourceConditionTableForm.validateTableForm().then((data) => {
          // 需要验证来源条件和来源范围至少有一条
          console.log(this.sourceRangeTableData, this.sourceConditionTableFormData)
          if (!this.sourceConditionTableFormData.length) {
            this.$message.error(this.$t('lang.gles.strategy.checkMsg01'))
            return
          }
          if (!this.sourceRangeTableData.length) {
            this.$message.error(this.$t('lang.gles.strategy.checkMsg02'))
            return
          }
          this.$refs.targetRangeForm.getValidateFormModel().then(async(targetScopemodel) => {
            // console.log(model, targetScopemodel, this.sourceRangeTableData, this.sourceConditionTableFormData)
            const param = { ...this.rowDetail, ...model }
            const sourceRangeTableData = this.sourceRangeTableData.length > 0 ? this.sourceRangeTableData.map((item, index) => {
              if (item.fixedGoodsAllocationId && Array.isArray(item.fixedGoodsAllocationId)) {
                item.fixedGoodsAllocationId = item.fixedGoodsAllocationId.join(',')
              }
              if (item.factoryPositionId && Array.isArray(item.factoryPositionId)) {
                item.factoryPositionId = item.factoryPositionId.join(',')
              }
              return { ...item, executionSequence: index + 1 }
            })
              : []
            const targetScopeList = []
            if (this.formModel.locationType === 2 || this.rowDetail.locationType === 2) {
              delete targetScopemodel.factoryPositionId
              if (targetScopemodel.warehouseAreaId) {
                if (!targetScopemodel.fixedGoodsAllocationId) {
                  delete targetScopemodel.fixedGoodsAllocationId
                }
                targetScopeList.push({ ...targetScopemodel })
              }
            } else {
              delete targetScopemodel.fixedGoodsAllocationId
              if (targetScopemodel.warehouseAreaId) {
                if (!targetScopemodel.factoryPositionId) {
                  delete targetScopemodel.factoryPositionId
                }
                targetScopeList.push({ ...targetScopemodel })
              }
            }

            const params = {
              ...param,
              sourceConditionDtoList: this.sourceConditionTableFormData,
              sourceScopeDtoList: sourceRangeTableData,
              targetScopeDtoList: targetScopeList
            }
            // console.log(params, 'params', targetScopeList)
            if (this.mode === 'edit') {
              params.sourceConditionDtoList = params.sourceConditionDtoList.map((item) => {
                return {
                  selectedCondition: item.selectedCondition,
                  operators: item.operators,
                  conditionValue: item.conditionValue
                }
              })
              params.sourceScopeDtoList = params.sourceScopeDtoList.map((item) => {
                return {
                  executionSequence: item.executionSequence,
                  factoryId: item.factoryId,
                  workshopId: item.workshopId,
                  warehouseId: item.warehouseId,
                  warehouseAreaId: item.warehouseAreaId,
                  factoryPositionId: item.factoryPositionId,
                  fixedGoodsAllocationId: item.fixedGoodsAllocationId
                }
              })
            }
            if (this.mode === 'copy') {
              delete params.id
              params.sourceConditionDtoList = params.sourceConditionDtoList.map((item) => {
                return {
                  selectedCondition: item.selectedCondition,
                  operators: item.operators,
                  conditionValue: item.conditionValue
                }
              })
              params.sourceScopeDtoList = params.sourceScopeDtoList.map((item) => {
                return {
                  executionSequence: item.executionSequence,
                  factoryId: item.factoryId,
                  workshopId: item.workshopId,
                  warehouseId: item.warehouseId,
                  warehouseAreaId: item.warehouseAreaId,
                  factoryPositionId: item.factoryPositionId,
                  fixedGoodsAllocationId: item.fixedGoodsAllocationId
                }
              })
            }
            // console.log(params, 'params', this.sourceRangeTableData)
            this.mode === 'edit' ? await this.update(params) : await this.insert(params)
          })
        })
      })
    },
    async insert(params) {
      const { code } = await this.$httpService(this.$apiStore.strategyManage('addTallyStrategy'), params)
      if (code) return
      this.mode === 'add' ? this.$message.success(this.$t('lang.gles.common.addSuccessfully')) : this.$message.success(this.$t('lang.gles.strategy.copySuccessfully'))
      this.onCancel()
    },
    async update(params) {
      const { code } = await this.$httpService(this.$apiStore.strategyManage('updateTallyStrategy'), params)
      if (code) return
      this.$message.success(this.$t('lang.gles.common.updateSuccessfully'))
      this.onCancel()
    },
    // 取消
    onCancel() {
      this.$emit('updateCom', {
        currentCom: 'tallyStrategyList',
        mode: ''
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.form_title {
  border-left: 4px solid #409eff;
  height: 18px;
  line-height: 20px;
  padding-left: 8px;
  font-size: 16px;
}
.tip {
  display: inline-block;
  position: absolute;
  top: 6px;
  right: -24px;
}
.required-icon {
  color: #f56c6c;
  vertical-align: middle;
}
.el-card__body {
  display: flex;
  flex-direction: column;
  .footer-btns {
    flex: 0 0 60px;
    left: 201px;
    bottom: 20px;
    z-index: 10;
    width: calc(100vw - 212px);
    height: 60px;
    line-height: 60px;
    margin-top: 10px;
    text-align: center;
  }
}
</style>
