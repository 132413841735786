<template>
  <el-dialog
    :title="$t('lang.gles.strategy.strategy.addSourceRange')"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    width="30%"
    @closed="reset"
  >
    <m-form
      ref="myRangeForm"
      :form-data="addSourceRangeFormData"
      label-position="right"
      :label-width="120"
      :extend-config="extendFormConfig"
    />
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">{{ $t('lang.gles.common.cancel') }}</el-button>
      <el-button
        type="primary"
        @click="onSubmit"
      >{{ $t('lang.gles.common.confirm') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import MForm from '@/libs_sz/components/MForm/MForm.vue'
import { getAddSourceRangeFormData } from '../data'
import addMixins from '@/mixins/addMixins'
import commonMixins from '@/mixins/commonMixins'
export default {
  name: 'AddSourceRange',
  components: { MForm },
  mixins: [addMixins, commonMixins],
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visible: false,
      // form表单相关的
      extendFormConfig: {
        isNeedBtn: false
      },
      row: {},
      options: {}
    }
  },
  computed: {
    addSourceRangeFormData() {
      return getAddSourceRangeFormData(this, { ...this.row }, { ...this.options })
    }
  },
  created() {
    this.initSelectList()
  },
  methods: {
    init(row, options) {
      this.visible = true
      this.row = row
      this.options = options
      if (this.row.positionType === 1) {
        this.getFactoryPositionList()
      } else {
        this.getFixGoodsPositionList()
      }
    },
    /**
     * 初始化下拉列表
     */
    initSelectList() {
      this.getWorkshopList()
      this.getWarehouseList()
      this.getWarehouseAreaList()
    },
    /**
     * 所属工厂 change
     * 工厂-车间
     */
    handleFactoryChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.$refs.myRangeForm.formModel, formItem.name, val)
      this.$refs.myRangeForm.formModel.workshopId = ''
      this.$refs.myRangeForm.formModel.warehouseId = ''
      this.$refs.myRangeForm.formModel.warehouseAreaId = ''
      this.$refs.myRangeForm.formModel.fixedGoodsAllocationId = []
      this.$refs.myRangeForm.formModel.factoryPositionId = []
      const params = { factoryId: this.$refs.myRangeForm.formModel.factoryId }
      this.getWorkshopList(params)
      this.getWarehouseList(params)
      this.getWarehouseAreaList()
    },
    /**
     * 所属车间 change
     * 车间-库区
     */
    handleWorkshopChange(val, formItem, changeFormValue, formModel) {
      val ? this.$refs.myRangeForm.clearValidate('warehouseId') : ''
      this.$set(this.$refs.myRangeForm.formModel, formItem.name, val)
      this.$refs.myRangeForm.formModel.warehouseAreaId = ''
      const params = { workshopId: this.$refs.myRangeForm.formModel.workshopId }
      this.getWarehouseAreaList(params)
    },
    /**
     * 所属仓库 change
     * 仓库-库区
     */
    handleWarehouseChange(val, formItem, changeFormValue, formModel) {
      val ? this.$refs.myRangeForm.clearValidate('workshopId') : ''
      this.$set(this.$refs.myRangeForm.formModel, formItem.name, val)
      this.$refs.myRangeForm.formModel.warehouseAreaId = ''
      const params = { warehouseId: this.$refs.myRangeForm.formModel.warehouseId }
      this.getWarehouseAreaList(params)
    },

    /**
     * 库区 change
     * 库区---库位和固定货位
     */
    handleWarehouseAreaChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.$refs.myRangeForm.formModel, formItem.name, val)
      this.$refs.myRangeForm.formModel.fixedGoodsAllocationId = []
      this.$refs.myRangeForm.formModel.factoryPositionId = []
      const params = { warehouseAreaId: this.$refs.myRangeForm.formModel.warehouseAreaId }
      if (this.row.positionType === 2) {
        this.getFixGoodsPositionList(params)
      } else {
        this.getFactoryPositionList(params)
      }
    },
    handleFormChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.$refs.myRangeForm.formModel, formItem.name, val)
    },
    reset() {
      delete this.mode
      this.$refs.myRangeForm.resetForm()
    },
    // 表单提交
    onSubmit() {
      this.$refs.myRangeForm.getValidateFormModel(true).then(async(model) => {
        const formModelArr = []
        if (model.fixedGoodsAllocationId && model.fixedGoodsAllocationId.length > 0) {
          model.fixedGoodsAllocationId.forEach(item => {
            formModelArr.push({ ...model, fixedGoodsAllocationId: item })
          })
        }
        if (model.factoryPositionId && model.factoryPositionId.length > 0) {
          model.factoryPositionId.forEach(item => {
            formModelArr.push({ ...model, factoryPositionId: item })
          })
        }
        if (!model.fixedGoodsAllocationId && !model.factoryPositionId) {
          formModelArr.push(model)
        }
        for (const v of this.tableData) {
          for (const t of formModelArr) {
            if (JSON.stringify(v) === JSON.stringify(t)) {
              this.$message.error(this.$t('lang.gles.tallyStrategy.checkMsg'))
              return
            }
          }
        }
        this.$emit('addRange', formModelArr)
        this.visible = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__footer{
    text-align: center;
}
.tip{
  display: inline-block;
  position:absolute;
  top: 6px;
  right:-24px;
}
</style>
