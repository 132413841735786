<template>
  <component
    :is="currentCom"
    :mode="mode"
    :row-detail.sync="row"
    :options="options"
    @updateCom="updateCom"
  />
</template>
<script>
import tallyStrategyList from './components/list.vue'
import tallyStrategyAdd from './components/add.vue'
export default {
  name: 'TallyStragy',
  components: {
    tallyStrategyList,
    tallyStrategyAdd
  },
  data() {
    return {
      currentCom: 'tallyStrategyList',
      mode: '',
      row: {},
      options: {}
    }
  },
  methods: {
    updateCom({ currentCom, mode, row, options }) {
      this.currentCom = currentCom
      this.mode = mode
      this.row = row
      this.options = options
    }
  }
}
</script>
<style lang="scss" scoped></style>
